<template>
    <div class="detail_box">
        <div class="base_info">
            <el-form
                ref="form"
                label-position="left"
                :model="form"
                size="small"
                label-width="88px"
            >
                <el-form-item label="已消耗名额" style="width: 40%">
                    <span class="info" style="color: #2370eb">{{
                        tableRow.consumedNumber || '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="剩余名额" style="width: 60%">
                    <span class="info" style="color: #d0021b">{{
                        tableRow.maxReportNumber - tableRow.consumedNumber ||
                        '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="试用数量" style="width: 40%">
                    <span class="info" style="color: #f5a623">{{
                        tableRow.trialNumber || '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="创建时间" style="width: 60%">
                    <span class="info">{{ tableRow.createTime || '- -' }}</span>
                </el-form-item>
            </el-form>
        </div>
        <el-form
            ref="form"
            label-position="left"
            size="small"
            label-width="100px"
        >
            <el-form-item label="品牌分类">
                <span class="brand">{{
                    tableRow.productType == 1 ? '腾讯企业邮箱' : '阿里企业邮箱'
                }}</span>
            </el-form-item>
            <el-form-item label="账户名称" required>
                <el-input
                    v-model="form.accountName"
                    placeholder="请输入账户名称"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="公司名称" required>
                <el-input
                    v-model="form.companyName"
                    placeholder="请输入公司名称"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="地区" required>
                <el-cascader
                    placeholder="请选择地区"
                    style="width: 240px"
                    clearable
                    v-model="address"
                    :options="$address.cascader()"
                ></el-cascader>
            </el-form-item>
            <el-form-item label="企业微信CorpID" required>
                <el-input
                    v-model="form.corpId"
                    placeholder="请输入企业微信CorpID"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="对接人" required>
                <el-input
                    v-model="form.opposite"
                    placeholder="请输入对接人"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="结算邮件地址" required>
                <el-input
                    v-model="form.settleEmailAddress"
                    placeholder="请输入结算邮件地址"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="当前可最大报备数" required>
                <el-input
                    v-model="form.maxReportNumber"
                    placeholder="请输入当前可最大报备数"
                    style="width: 240px"
                    @input="
                        () => {
                            form.maxReportNumber = form.maxReportNumber.replace(
                                /[^0-9]/g,
                                ''
                            );
                        }
                    "
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: #2370eb;
                    border-color: #d5d6df;
                    color: #fff;
                    margin-right: 15px;
                "
                @click="onSubmitSave"
                >保存</el-button
            >
        </div>
    </div>
</template>

<script>
import { updateAccount } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                accountName: '',
                companyName: '',
                corpId: '',
                opposite: '',
                settleEmailAddress: '',
                maxReportNumber: '',
            },
            tableRow: {},
            address: '',
            btnP: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data, btnP) {
            this.btnP = btnP;
            this.tableRow = data;
            this.form.accountName = data.accountName;
            this.form.companyName = data.companyName;
            this.form.corpId = data.corpId;
            this.form.opposite = data.opposite;
            this.form.settleEmailAddress = data.settleEmailAddress;
            this.form.maxReportNumber = data.maxReportNumber;
            if (data.cityId) {
                this.address = [String(data.provinceId), String(data.cityId)];
            } else {
                this.address = [String(data.provinceId)];
            }
        },
        onSubmitSave() {
            let data = {
                param: {
                    ...this.form,
                    provinceId: this.address[0] || 0,
                    cityId: this.address[1] || 0,
                    id: this.tableRow.id,
                },
            };
            if (!this.form.accountName) {
                return this.$message.error('请输入账号名称');
            }
            if (!this.form.companyName) {
                return this.$message.error('请输入公司名称');
            }
            if (!data.param.provinceId) {
                return this.$message.error('请选择地区');
            }
            if (!this.form.corpId) {
                return this.$message.error('请输入企业微信corpId');
            }
            if (!this.form.opposite) {
                return this.$message.error('请输入对接人');
            }
            if (!this.form.settleEmailAddress) {
                return this.$message.error('请输入结算邮件地址');
            }
            if (!this.form.maxReportNumber) {
                return this.$message.error('请输入当前可报备最大数');
            }
            if (this.form.productType == 1 && this.btnP.tmReport) {
                data.param.productType = 5;
            }
            updateAccount(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            for (const i in this.form) {
                this.form[i] = '';
            }
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.detail_box {
    width: 100%;
    padding-bottom: 50px;
    .el-form {
        width: 80%;
        margin: 20px auto;
    }
    .base_info {
        width: 100%;
        height: 89px;
        border-bottom: 1px dashed #e5e5e5;
        padding-top: 12px;
        box-sizing: border-box;
        .el-form {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0;
            .el-form-item--small.el-form-item {
                margin-bottom: 0 !important;
            }
        }
    }
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        background: #fafafa;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .bottom-but {
        width: 500px;
        line-height: 48px;
        text-align: right;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
