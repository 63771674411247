<template>
    <div class="report_manage_box">
        <div class="data_statistics">
            <div class="data_statistics_left" v-if="btnP.tmMail">
                <p class="title">
                    <i class="iconfont icon-tengxunqiyeyouxiang"></i>
                    腾讯企业邮箱
                </p>
                <div class="content">
                    <p>
                        <span>{{ tmBrief.maxReportNumber || '0' }}</span>
                        <span>当前可报备最大数</span>
                    </p>
                    <p>
                        <span>{{
                            tmBrief.consumedNumber +
                                tmBrief.channelReportNumber || '0'
                        }}</span>
                        <span>已消耗名额</span>
                    </p>
                    <p>
                        <span>{{ tmBrief.assignedNumber || '0' }}</span>
                        <span>已分配名额</span>
                    </p>
                    <p>
                        <span class="blue">{{
                            tmBrief.maxReportNumber - tmBrief.assignedNumber ||
                            '0'
                        }}</span>
                        <span>可分配名额</span>
                    </p>
                </div>
            </div>
            <div
                class="data_statistics_right"
                style="margin: 0 10px"
                v-if="btnP.aliMail"
            >
                <p class="title">
                    <i class="iconfont icon-alimailaliyouxiang"></i>
                    阿里企业邮箱
                </p>
                <div class="content">
                    <p>
                        <span>{{ alBrief.maxReportNumber || '0' }}</span>
                        <span>当前可报备最大数</span>
                    </p>
                    <p>
                        <span>{{
                            alBrief.consumedNumber +
                                alBrief.channelReportNumber || '0'
                        }}</span>
                        <span>已消耗名额</span>
                    </p>
                    <p>
                        <span>{{ alBrief.assignedNumber || '0' }}</span>
                        <span>已分配名额</span>
                    </p>
                    <p>
                        <span class="orange">{{
                            alBrief.maxReportNumber - alBrief.assignedNumber ||
                            '0'
                        }}</span>
                        <span>可分配名额</span>
                    </p>
                </div>
            </div>
            <div class="data_statistics_right" v-if="btnP.wyMail">
                <p class="title">
                    <i class="iconfont icon-alimailaliyouxiang"></i>
                    网易企业邮箱
                </p>
                <div class="content">
                    <p>
                        <span>{{ wyBrief.maxReportNumber || '0' }}</span>
                        <span>当前可报备最大数</span>
                    </p>
                    <p>
                        <span>{{
                            wyBrief.consumedNumber +
                                wyBrief.channelReportNumber || '0'
                        }}</span>
                        <span>已消耗名额</span>
                    </p>
                    <p>
                        <span>{{ wyBrief.assignedNumber || '0' }}</span>
                        <span>已分配名额</span>
                    </p>
                    <p>
                        <span class="orange">{{
                            wyBrief.maxReportNumber - wyBrief.assignedNumber ||
                            '0'
                        }}</span>
                        <span>可分配名额</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="table-title">
            <div>
                <div class="title_left_btn">
                    <span>品牌分类</span>
                    <el-radio-group
                        size="small"
                        v-model="returnStatusVal"
                        @change="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                    >
                        <el-radio-button
                            label="腾讯"
                            v-if="btnP.tmMail"
                        ></el-radio-button>
                        <el-radio-button
                            label="阿里"
                            v-if="btnP.aliMail"
                        ></el-radio-button>
                        <el-radio-button
                            label="网易"
                            v-if="btnP.wyMail"
                        ></el-radio-button>
                    </el-radio-group>
                    <span style="margin-left: 20px">账户筛选</span>
                    <el-input
                        size="small"
                        style="width: 200px"
                        placeholder="请输入公司名称/账户名称"
                        v-model.trim="name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="
                            (currentPage = 1),
                                (pagesize = 20),
                                (returnStatusVal = ''),
                                getTableData()
                        "
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    >
                    </el-button>
                </div>
            </div>
            <el-button
                class="add"
                size="small"
                @click="onAddAccount"
                v-if="btnP.add"
            >
                + 新增账户
            </el-button>
        </div>
        <Table
            ref="table"
            @onDetail="onDetail"
            @onUpdate="onUpdate"
            @totalNum="totalNum"
        ></Table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            size="500px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增账户
                </div>
            </template>
            <Add @close="handleClose" ref="add" />
        </el-drawer>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawerDetails"
            :direction="direction"
            size="500px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    账户详情
                </div>
            </template>
            <Detail @close="handleClose" ref="detail" />
        </el-drawer>
        <!-- 调增名额 -->
        <el-dialog
            :visible.sync="dialogUpdate"
            width="560px"
            :before-close="handleDialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    调增名额
                </div>
            </template>
            <p class="dl_con">
                <span>当前可最大报备数</span>
                <el-input
                    v-model="maxReportNumber"
                    placeholder="请输入当前可最大报备数"
                    style="width: 240px"
                    size="small"
                    @input="
                        () => {
                            maxReportNumber = maxReportNumber.replace(
                                /[^0-9]/g,
                                ''
                            );
                        }
                    "
                ></el-input>
            </p>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleDialog" round size="mini"
                    >取 消</el-button
                >
                <el-button type="primary" @click="onSave" round size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getAccount,
    updateAccount,
    accountBrief,
} from '@/api/report/report.js';
import { accountBrief as newAccountBrief } from '@/api/report/newReport.js';
import Table from '../table/table.vue';
import Add from '../add/add.vue';
import Detail from '../detail/detail.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            direction: 'rtl',
            drawerAdd: false,
            drawerDetails: false,
            dialogUpdate: false,
            btnP: {},
            name: '',
            total: 0,
            pagesize: 20,
            currentPage: 1,
            maxReportNumber: 0,
            tableRow: {},
            tmBrief: {},
            alBrief: {},
            wyBrief: {},
            returnStatusVal: '腾讯',
        };
    },
    components: {
        Add,
        Detail,
        Table,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            if (btn.tmMail && !btn.aliMail && !btn.wyMail) {
                this.returnStatusVal = '腾讯';
            }
            if (btn.aliMail && !btn.tmMail && !btn.wyMail) {
                this.returnStatusVal = '阿里';
            }
            if (btn.wyMail && !btn.tmMail && !btn.aliMail) {
                this.returnStatusVal = '网易';
            }
            if (!btn.tmMail && btn.aliMail && btn.wyMail) {
                his.returnStatusVal = '阿里';
            }
            this.getData();
        },
        getTableData() {
            this.currentPage = 1;
            this.getData();
        },
        accountBrief(productType) {
            let data = {
                param: {
                    productType,
                },
            };
            if (this.btnP.tmReport && productType == 5) {
                newAccountBrief(data).then((res) => {
                    if (res.code == 200) {
                        this.tmBrief = res.data;
                    }
                });
            }
            accountBrief(data).then((res) => {
                if (res.code == 200) {
                    if (productType == 1) {
                        this.tmBrief = res.data;
                    } else if (productType == 2) {
                        this.alBrief = res.data;
                    } else if (productType == 4) {
                        this.wyBrief = res.data;
                    }
                }
            });
        },
        getData() {
            if (this.btnP.tmReport) {
                this.accountBrief(5);
            } else {
                this.accountBrief(1);
            }

            this.accountBrief(2);
            this.accountBrief(4);
            this.$refs.table.getData(
                this.name,
                this.currentPage,
                this.pagesize,
                this.btnP,
                this.returnStatusVal
            );
        },
        totalNum(num) {
            this.total = num;
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        getAccount() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            getAccount(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerDetails) {
                        this.$refs.detail.getData(res.data, this.btnP);
                    }
                }
            });
        },
        onAddAccount() {
            this.drawerAdd = true;
            setTimeout(() => {
                this.$refs.add.getData(this.btnP);
            }, 0);
        },
        onSave() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    maxReportNumber: this.maxReportNumber,
                },
            };
            if (!this.maxReportNumber && Number(this.maxReportNumber) >= 0) {
                return this.$message.error('请输入最大报备数量');
            }
            updateAccount(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleDialog();
                }
            });
        },
        onDetail(row) {
            this.tableRow = row;
            this.drawerDetails = true;
            this.getAccount();
        },
        onUpdate(row) {
            this.tableRow = row;
            this.dialogUpdate = true;
            this.maxReportNumber = row.maxReportNumber || 0;
        },
        handleDialog() {
            this.dialogUpdate = false;
            this.form = {};
            this.getData();
        },
        handleClose() {
            this.drawerDetails = false;
            this.drawerAdd = false;
            this.getData();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.report_manage_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .data_statistics {
        height: 120px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        > div {
            flex: 1;
            background-color: #fff;
            border-radius: 6px;
            box-sizing: border-box;
            padding: 12px 16px;
            p {
                i {
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    font-size: 16px;
                    text-align: center;
                    line-height: 28px;
                    font-weight: normal;
                    margin-right: 6px;
                }
                font-size: 14px;
                font-weight: 600;
            }
            div {
                margin-top: 10px;
                border-radius: 4px;
                height: 60px;
                display: flex;
                justify-content: space-around;
                width: 100%;
                p {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-evenly;
                    span:first-child {
                        font-size: 18px;
                        font-weight: 600;
                    }
                    span:last-child {
                        font-size: 14px;
                        font-weight: normal;
                    }
                }
                .blue {
                    color: #2370eb;
                }
                .orange {
                    color: #ff562d;
                }
            }
        }
        .data_statistics_left {
            .title {
                i {
                    background-color: #e9f2ff;
                    color: #2370eb;
                }
            }
            div {
                background-color: #f7f9fd;
            }
        }
        .data_statistics_right {
            .title {
                i {
                    background-color: #fff0e9;
                    color: #ff562d;
                }
            }
            div {
                background-color: #fef6f3;
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title_left_btn {
            span {
                display: inline-block;
                margin-right: 10px;
                font-size: 13px;
                font-weight: 600;
            }
            button {
                background: #2370eb;
                border-radius: 2px;

                margin-right: 16px;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #333333;
            padding: 5px 10px;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        // button {
        //     font-size: 12px;
        //     padding: 0 20px 0 12px;
        //     height: 26px;
        //     background: #ffffff;
        //     border-radius: 2px;
        //     border: 1px solid #2370eb;
        //     cursor: pointer;
        //     color: #2370eb;
        //     margin-left: 16px;
        //     background: #2370eb;
        //     i {
        //         margin-right: 6px;
        //         font-size: 12px;
        //     }
        // }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    color: #333;
    font-weight: 600;
    font-size: 13px;
    span {
        margin-right: 10px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
    .el-button--primary {
        background-color: #2370eb;
    }
}
.el-pagination {
    margin: 8px 0;
}
</style>
