<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="8" show-overflow-tooltip> </el-table-column>
            <el-table-column label="账户名称" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.detail"
                        type="text"
                        style="color: #2370eb"
                        @click="onDetail(scope.row)"
                        >{{ scope.row.accountName || '- -' }}</el-button
                    >
                    <span v-else>
                        {{ scope.row.accountName || '- -' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="品牌分类" width="110" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.productType == 1
                                ? '腾讯企业邮箱'
                                : scope.row.productType == 2
                                ? '阿里企业邮箱'
                                : '网易企业邮箱'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="当前可报备最大数"
                show-overflow-tooltip
                width="120"
            >
                <template slot-scope="scope">
                    <div>
                        <span style="display: inline-block; width: 80px">
                            {{ scope.row.maxReportNumber || '- -' }}
                        </span>
                        <el-button
                            v-if="btnP.update"
                            type="text"
                            @click="onUpdate(scope.row)"
                            style="color: #2370eb"
                            >编辑</el-button
                        >
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="已消耗名额"
                width="90"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.consumedNumber +
                                scope.row.channelConsumedNumber || '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="剩余名额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.maxReportNumber -
                            Number(scope.row.consumedNumber) -
                            Number(scope.row.channelConsumedNumber)
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="试用数量" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.trialNumber || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="公司名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.companyName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="地区" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <div>
                        {{
                            $address.address(
                                scope.row.provinceId,
                                scope.row.cityId
                            )
                        }}
                    </div>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
import { accountList } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            btnP: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(name, pageNum, pageSize, btnP, status) {
            this.btnP = btnP;
            let data = {
                param: {},
                pageNum,
                pageSize,
            };
            if (name) {
                data.param.name = name;
            }
            if (status == '腾讯') {
                if (btnP.tmReport) {
                    data.param.productType = 5;
                } else {
                    data.param.productType = 1;
                }
            }
            if (status == '阿里') {
                data.param.productType = 2;
            }
            if (status == '网易') {
                data.param.productType = 4;
            }
            accountList(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    console.log(this.tableData);
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                }
            });
        },
        onDetail(row) {
            this.$emit('onDetail', row);
        },
        onUpdate(row) {
            this.$emit('onUpdate', row);
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
}
/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.el-button + .el-button {
    margin-left: 0;
}
</style>
