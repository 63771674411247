<template>
    <div class="add_box">
        <el-form
            ref="form"
            label-position="left"
            size="small"
            label-width="100px"
        >
            <el-form-item label="品牌分类" required>
                <el-select
                    v-model="form.productType"
                    size="small"
                    style="width: 240px"
                    placeholder="请选择品牌分类"
                >
                    <el-option label="腾讯企业邮箱" :value="1"> </el-option>
                    <el-option label="阿里企业邮箱" :value="2"> </el-option>
                    <el-option label="网易企业邮箱" :value="4"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="账户名称" required>
                <el-input
                    v-model="form.accountName"
                    placeholder="请输入账户名称"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="公司名称" required>
                <el-input
                    v-model="form.companyName"
                    placeholder="请输入公司名称"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="地区" required>
                <el-cascader
                    placeholder="请选择地区"
                    style="width: 240px"
                    clearable
                    v-model="address"
                    :options="$address.cascader()"
                ></el-cascader>
            </el-form-item>
            <el-form-item label="企业微信CorpID" required>
                <el-input
                    v-model="form.corpId"
                    placeholder="请输入企业微信CorpID"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="对接人" required>
                <el-input
                    v-model="form.opposite"
                    placeholder="请输入对接人"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="结算邮件地址" required>
                <el-input
                    v-model="form.settleEmailAddress"
                    placeholder="请输入结算邮件地址"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="当前可最大报备数">
                <el-input
                    v-model="form.maxReportNumber"
                    placeholder="请输入当前可最大报备数"
                    style="width: 240px"
                    @input="
                        () => {
                            form.maxReportNumber = form.maxReportNumber.replace(
                                /[^0-9]/g,
                                ''
                            );
                        }
                    "
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: #2370eb;
                    border-color: #d5d6df;
                    color: #fff;
                    margin-right: 20px;
                "
                @click="onSubmitSave"
                >保存</el-button
            >
        </div>
    </div>
</template>

<script>
import { addAccount } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {},
            address: '',
            btnP: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(btnP) {
            this.btnP = btnP;
        },
        onSubmitSave() {
            let data = {
                param: {
                    ...this.form,
                    provinceId: this.address[0] || 0,
                    cityId: this.address[1] || 0,
                },
            };
            if (!this.form.productType) {
                return this.$message.error('请选择品牌分类');
            }
            if (!this.form.accountName) {
                return this.$message.error('请输入账号名称');
            }
            if (!this.form.companyName) {
                return this.$message.error('请输入公司名称');
            }
            if (!data.param.provinceId) {
                return this.$message.error('请选择地区');
            }
            if (!this.form.corpId) {
                return this.$message.error('请输入企业微信corpId');
            }
            if (!this.form.opposite) {
                return this.$message.error('请输入对接人');
            }
            if (!this.form.settleEmailAddress) {
                return this.$message.error('请输入结算邮件地址');
            }
            if (this.form.productType == 1 && this.btnP.tmReport) {
                data.param.productType = 5;
            }
            addAccount(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.form = {};
            this.address = '';
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.add_box {
    width: 100%;
    padding-bottom: 50px;
    .el-form {
        width: 80%;
        margin: 20px auto;
    }
    .bottom-but {
        width: 500px;
        line-height: 48px;
        text-align: right;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
